import { computed } from 'vue';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { userData as userDataGetter } from '@/stores/User/UserData/UserDataMapper';
import { getOperator } from '@/stores/Operator/OperatorMapper';
import DrivingLicenseRoadblockComponent from '@/components/DrivingLicenseRoadblock/DrivingLicenseRoadblockComponent';
import { FullScreenManagerInstance as fullScreen } from '@/components/motion-ui-legacy/MuiFullScreen/FullScreenManager';

export const useDrivingLicenseRoadblock = () => {
  const userData = computed(userDataGetter);

  const showRoadblock = async config => {
    const {
      onDrivingLicenseCreated = noop,
      onBeforeClose = noop,
      onAfterClose = noop,
      onError = noop,
      hasCompletedBadge,
    } = config || {};

    const operatorUuid = get(userData, 'value.cs_operator_uuid');

    if (!operatorUuid) {
      throw new Error('operatorUuid does not exist');
    }

    const csOperator = await getOperator(operatorUuid);

    if (isEmpty(csOperator)) {
      throw new Error('operator does not exist');
    }

    fullScreen.show(DrivingLicenseRoadblockComponent, {
      props: {
        userUuid: get(userData, 'value.uuid'),
        hasBadge: Boolean(hasCompletedBadge),
        csOperator,
      },
      listeners: {
        'on:close': async () => {
          await onBeforeClose();
          fullScreen.close();
          await onAfterClose();
        },
        'on:driving-license-created': onDrivingLicenseCreated,
        'on:error': onError,
      },
    });
  };

  return {
    showRoadblock,
  };
};
